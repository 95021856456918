import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import { getStorage } from "firebase/storage";

export const firebaseApp = initializeApp({
    apiKey: "AIzaSyBApGmOfcGrDM2GE7N30OCJS4lSzHbZd50",
    authDomain: "pulpitmagzine.firebaseapp.com",
    projectId: "pulpitmagzine",
    storageBucket: "pulpitmagzine.appspot.com",
    messagingSenderId: "171445566778",
    appId: "1:171445566778:web:bd8d7e648d9fe96f212403",
    measurementId: "G-8H3RP2N3W5",
})

// used for the firestore refs
export const db = getFirestore(firebaseApp)
export const auth  = getAuth(firebaseApp)
export const storage = getStorage(firebaseApp)

// export const analytics = getAnalytics(app)
