import { defineStore } from 'pinia';
import { collection, getDocs,} from "firebase/firestore";
import { db} from "@/firebase";


export const useReportStore = defineStore('reports', {
    state: () => ({
        totalAdverts: 0,
        totalIssues: 0,
        totalEvents: 0,
        loading: false,
        error: null as any,
    }),
    actions: {

        async fetchTotals() {
            this.loading = true;
            this.error = null;

            try {
                const eventRef = collection(db, "events");
                const issuesRef = collection(db, "issues");
                const advertRef = collection(db, "adverts");

                const querySnapshot = await getDocs(eventRef);
                const querySnapshot1 = await getDocs(issuesRef);
                const querySnapshot2 = await getDocs(advertRef);

                this.totalEvents = querySnapshot.size;
                this.totalIssues = querySnapshot1.size;
                this.totalAdverts = querySnapshot2.size;

            } catch (error) {
                console.error("Error fetching adverts:", error);
                this.error = error;
            } finally {
                this.loading = false;
            }
        },

    },
});
