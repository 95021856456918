import { defineStore } from 'pinia';
import { collection, getDocs, addDoc, updateDoc, doc } from "firebase/firestore";
import { db, storage } from "@/firebase";
import { getDownloadURL, uploadBytesResumable, ref as fileRef } from 'firebase/storage';


export const useEventsStore = defineStore('events', {
  state: () => ({
    events: [] as any,
    loading: false,
    error: null as any,
  }),
  actions: {
    
    async fetchEvents() {
      this.loading = true;
      this.error = null;

      try {
        const eventRef = collection(db, "events");
        const querySnapshot = await getDocs(eventRef);
        this.events = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as any[]; 
      } catch (error) {
        console.error("Error fetching events:", error);
        this.error = error;
      } finally {
        this.loading = false;
      }
    },
    async create(event: any) {
      this.error = null;

      try {
        const eventRef = collection(db, "events");
        const docRef = await addDoc(eventRef, event);
        console.log("Document written with ID:", docRef.id);
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.error("Error creating event:", error);
        this.error = error;
      } finally {
       // this.loading = false;
        await this.fetchEvents(); // Refresh events after creation
      }
    },
    async update(id: string, event: any) {
     
      this.error = null;

      try {
        const eventRef = doc(collection(db, "events"), id);
        await updateDoc(eventRef, event);
        console.log("Document updated with ID:", id);
      } catch (error) {
        console.error("Error updating event:", error);
        this.error = error;
      } finally {
        this.loading = false;
      }
    },

    async uploadEventImageUpdate(id: any, eventObj: any) {

        this.loading = true;

        if (!eventObj.image || !eventObj.image.type.startsWith('image/')) {
            throw new Error('Invalid image file. Please select a JPEG or PNG image.');
          }
          

        const imageName = `${Date.now()}-${eventObj.image.name}`;
        const imageRef = fileRef(storage, `events/${imageName}`);

        const metadata = {
            contentType: eventObj.image.type,
        };
        const uploadTask = uploadBytesResumable(imageRef, eventObj.image, metadata)

        uploadTask.on('state_changed',
            (snapshot) => {
                  const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                    case 'paused':
                        console.log('Upload is paused');
                        break;
                    case 'running':
                        console.log('Upload is running');
                        break;
                }
            },
            (error: any) => {
                this.loading = false;
            },
           async  () => {
                
              const downloadURL =  await getDownloadURL(uploadTask.snapshot.ref);

              eventObj.image = downloadURL
              console.log("ID", id);
              
                await  this.update(id, eventObj);
            });
        },

        async uploadEventImage (eventObj: any) {

            this.loading = true;

            const imageRef = fileRef(storage, `events/${eventObj.image.name}`);
            const metadata = {
                contentType: 'image/jpeg',
            };
            const uploadTask = uploadBytesResumable(imageRef, eventObj.image, metadata)
    
            uploadTask.on('state_changed',
                (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log('Upload is ' + progress + '% done');
                    switch (snapshot.state) {
                        case 'paused':
                            console.log('Upload is paused');
                            break;
                        case 'running':
                            console.log('Upload is running');
                            break;
                    }
                },
                (error: any) => {
                    this.loading = false;
                },
                async () => {
                    
                  await   getDownloadURL(uploadTask.snapshot.ref).then( async (downloadURL) => {
                        console.log('File available at', downloadURL);
                        eventObj.image = downloadURL
                        eventObj['status'] = "Draft"
                       await  this.create(eventObj);
    
                    });
                });
            },   
            
    //publish and unpublish
    async publishEvent(eventId: any) {

      this.loading = true;

      try {
        const eventsRef = doc(collection(db, "events"), eventId);
        await updateDoc(eventsRef, {
          status: "Published"
        });
        console.log("Document updated with ID:", eventId);
      } catch (error) {
        console.error("Error updating events:", error);
        this.error = error;
      } finally {
        this.loading = false;
      }

    },

    async unpublishEvent(eventId: any) {

      this.loading = true;

      try {
        const eventsRef = doc(collection(db, "events"), eventId);
        await updateDoc(eventsRef, {
          status: "Draft"
        });
        console.log("Document updated with ID:", eventId);
      } catch (error) {
        console.error("Error updating event:", error);
        this.error = error;
      } finally {
        this.loading = false;
      }
    }
  },
});
