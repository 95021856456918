<template>
<Breadcrumb breadcrumb="Update Event" /> 
<div class="grid grid-cols-2 gap-4">
  <form v-if="eventOne" @submit.prevent="handleSubmit" class="p-4 md:p-5">
        <div class="grid gap-4 mb-4 grid-cols-2">
          <div class="col-span-2 sm:col-span-1">
            <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Title</label>
            <input type="text" name="name" id="name" v-model="formData.title" :disabled="eventOne.status === 'Published'"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              placeholder="Title" required="">
          </div>
          <div class="col-span-2 sm:col-span-1">
            <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white" for="user_avatar">Event
              Image </label>
            <input :disabled="eventOne.status === 'Published'"
              class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
              aria-describedby="user_avatar_help" id="user_avatar" type="file" v-on:change="handleImageChange">
            <div class="mt-1 text-sm text-gray-500 dark:text-gray-300" id="user_avatar_help">Event imgae to show on
              website events</div>
  
          </div>
          <div class="col-span-2 sm:col-span-1">
            <label for="price" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Event Date</label>
            <input type="date" name="price" id="price" v-model="formData.date" :disabled="eventOne.status === 'Published'"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              required="">
          </div>
          <div class="col-span-2 sm:col-span-1">
            <label for="category" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Category</label>
            <select id="category" v-model="formData.category" :disabled="eventOne.status === 'Published'"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500">
              <option selected="">Select category</option>
              <option value="Music">MUSIC</option>
            <option value="Youth">YOUTH</option>
            <option class="" value="PREMARITAL AND PRE-ENGAGEMENT COUNSELLING">PREMARITAL AND PRE-ENGAGEMENT COUNSELLING</option>
            <option value="Youth">OUTREACH</option>
            <option value="CHILDRENS MINISTRY">CHILDRENS MINISTRY</option>
            </select>
          </div>
          <div class="col-span-2">
            <label for="description" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Content</label>
            <textarea id="description" rows="4" v-model="formData.content" :disabled="eventOne.status === 'Published'"
              class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Write event description here"></textarea>
          </div>
        </div>
        <button type="submit" v-if="eventOne.status === 'Draft'"
          class="text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
          <svg class="me-1 -ms-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
              d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
              clip-rule="evenodd"></path>
          </svg>
          Update event
        </button>
      </form>

  <div>
    <div v-if="eventOne" class="card relative mt-4">
            <img class="w-full h-56 rounded-t-lg object-cover" :src="eventOne.image"
                alt="Noteworthy technology acquisitions 2021">
            <div class="card-content p-4 leading-normal flex flex-col justify-between bg-white rounded-b-lg shadow-md">
                <h5 class="card-title mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{{eventOne.title}}</h5>
                <p class="card-text mb-3 font-normal text-gray-700 dark:text-gray-400">{{eventOne.content}}</p>

                <div class="flex items-center mt-auto space-x-4">
                    <div class="flex items-center">

                        <span class="text-sm font-medium text-gray-700"> Category: </span>
                        <span
                            class="ml-1 inline-flex items-center px-2 py-1 text-xs font-bold leading-none bg-orange-500 text-white rounded-sm mr-2">
                            {{eventOne.category}}
                        </span>
                    </div>
                    <div class="flex items-center">
                        <span class="text-sm font-medium text-gray-700"> Created:</span>
                        <span
                            class="ml-1 inline-flex items-center px-2 py-1 text-xs font-bold leading-none bg-gray-500 text-white rounded-sm">
                           {{  formatDate(eventOne.date_to) }}
                        </span>

                    </div>

                    <div class="flex items-center">
                        <span class="text-sm font-medium text-gray-700"> Status:</span>
                        <span
                            class="ml-1 inline-flex items-center px-2 py-1 text-xs font-bold leading-none bg-green-500 text-white rounded-sm">
                            {{ eventOne.status }}
                        </span>

                    </div>
                </div>



            </div>
        </div>
  </div>
</div>
  
  </template>
  <style scoped>
  /* Add custom styles for a more polished look */
  .container {
      /* Adjust container width as needed */
      max-width: 800px;
  }
  
  .card {
      /* Increase hover effect opacity */
      opacity: 0.95;
      transition: opacity 0.2s ease-in-out;
  }
  
  .card:hover {
      opacity: 1;
  }
  
  .card-title {
      /* Optional: Adjust heading color for dark mode contrast */
      color: #333 !important;
      /* Override dark mode color if needed */
  }
  
  /* Add additional styles as desired (font sizes, colors, spacing) */
  </style>
  <script setup>
  import { useRoute } from 'vue-router'
  
  import { useEvents } from '@/hooks/useEvents';
  import { useDate } from '@/hooks/useDate';
import { onMounted, ref } from 'vue';
import Breadcrumb from '@/partials/AppBreadcrumb.vue'

const route = useRoute()
const id = route.params.id;

const { findOne } = useEvents();
const { formatDate } = useDate();

const eventOne = ref();

 
const formData = ref({
    title: '',
    image: null, // Assuming image is a file
    date: '',
    category: '',
    content: '', // Assuming content refers to the textarea value
    status: 'Draft'
  });


onMounted(async ()=>{
    eventOne.value = await findOne(id);
    if(eventOne.value){
    formData.value = eventOne.value
  }
})

  
  const { uploadEventImageUpdate } = useEvents();
  
  
  const handleImageChange = (event) => {
    formData.value.image = event.target.files[0];
  };
  
  const handleSubmit = (event) => {
    event.preventDefault();
  
    // Submit form data here (e.g., send to server using an API call)
    console.log('Submitted form data:', formData.value.image.name);
  
    uploadEventImageUpdate(id,formData.value);
  
    
  };
  
  </script>

  