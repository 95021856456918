import { defineStore } from 'pinia';
import { collection, getDocs, addDoc, updateDoc, doc } from "firebase/firestore";
import { db, storage } from "@/firebase";
import { getDownloadURL, uploadBytesResumable, ref as fileRef } from 'firebase/storage';


export const useIssuesStore = defineStore('issues', {
  state: () => ({
    issues: [] as any,
    loading: false,
    error: null as any,
  }),
  actions: {

    async fetchIssues() {
      this.loading = true;
      this.error = null;

      try {
        const issuesRef = collection(db, "issues");
        const querySnapshot = await getDocs(issuesRef);
        this.issues = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as any[];
      } catch (error) {
        console.error("Error fetching issues:", error);
        this.error = error;
      } finally {
        this.loading = false;
      }
    },
    async create(issues: any) {
      this.error = null;

      try {
        const issuesRef = collection(db, "issues");
        const docRef = await addDoc(issuesRef, issues);
        console.log("Document written with ID:", docRef.id);
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.error("Error creating issues:", error);
        this.error = error;
      } finally {
        // this.loading = false;
        await this.fetchIssues(); // Refresh issues after creation
      }
    },
    async update(id: string, issues: any) {

      this.error = null;

      try {
        const issuesRef = doc(collection(db, "issues"), id);
        await updateDoc(issuesRef, issues);
        console.log("Document updated with ID:", id);
      } catch (error) {
        console.error("Error updating issues:", error);
        this.error = error;
      } finally {
        this.loading = false;
      }
    },

    async uploadIssueImageUpdate(id: any, issuesObj: any) {

      this.loading = true;

      if (!issuesObj.image || !issuesObj.image.type.startsWith('image/')) {
        throw new Error('Invalid image file. Please select a JPEG or PNG image.');
      }


      const imageName = `${Date.now()}-${issuesObj.image.name}`;
      const imageRef = fileRef(storage, `issues/${imageName}`);

      const metadata = {
        contentType: issuesObj.image.type,
      };
      const uploadTask = uploadBytesResumable(imageRef, issuesObj.image, metadata)

      uploadTask.on('state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
          switch (snapshot.state) {
            case 'paused':
              console.log('Upload is paused');
              break;
            case 'running':
              console.log('Upload is running');
              break;
          }
        },
        (error: any) => {
          this.loading = false;
        },
        async () => {

          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

          issuesObj.image = downloadURL
          console.log("ID", id);

          await this.update(id, issuesObj);
        });
    },

    async uploadIssueImage(issuesObj: any) {

      this.loading = true;

      const imageRef = fileRef(storage, `issues/${issuesObj.pdf.name}`);

      const uploadTask = uploadBytesResumable(imageRef, issuesObj.pdf)

      uploadTask.on('state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
          switch (snapshot.state) {
            case 'paused':
              console.log('Upload is paused');
              break;
            case 'running':
              console.log('Upload is running');
              break;
          }
        },
        (error: any) => {
          this.loading = false;
        },
        async () => {

          await getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
            console.log('File available at', downloadURL);
            issuesObj.pdf = downloadURL
            issuesObj.image = "https://thepulpitmagazine.com/images/COVER.jpg";
            issuesObj['status'] = "Draft"
            await this.create(issuesObj);

          });
        });
    },



    //publish and unpublish
    async publishIssue(issueId: any) {

      this.loading = true;

      try {
        const issuesRef = doc(collection(db, "issues"), issueId);
        await updateDoc(issuesRef, {
          status: "Published"
        });
        console.log("Document updated with ID:", issueId);
      } catch (error) {
        console.error("Error updating issues:", error);
        this.error = error;
      } finally {
        this.loading = false;
      }

    },

    async unpublishIssue(issueId: any) {

      this.loading = true;

      try {
        const issuesRef = doc(collection(db, "issues"), issueId);
        await updateDoc(issuesRef, {
          status: "Draft"
        });
        console.log("Document updated with ID:", issueId);
      } catch (error) {
        console.error("Error updating issues:", error);
        this.error = error;
      } finally {
        this.loading = false;
      }
    }
  },
});
