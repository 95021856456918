
import { Timestamp } from 'firebase/firestore'; // Import Timestamp
import dayjs from 'dayjs'; // Import dayjs library (install if needed)



export function useDate() {
    const formatDate = (date: Timestamp | Date) => {
        try {
          if (date instanceof Timestamp) {
            const jsDate = date.toDate();
            return dayjs(jsDate).format('DD/MM/YYYY');
          } else {
           return dayjs(date).format('DD/MM/YYYY');
          }
        } catch (error) {
          console.error('Error formatting date:', error);
          // Handle the error appropriately (e.g., return a default value)
          return 'Invalid Date'; // Example default value
        }
      };

      const truncate = (content: any, maxLength = 20) =>  { 
        if (!content) return;
        return content.slice(0, maxLength) + (content.length > maxLength ? '...' : '');
      }

  return {
   formatDate,
   truncate
  };
}
