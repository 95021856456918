import { ref, onMounted } from "vue";
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, updateDoc } from "firebase/firestore";
import { ref as fileRef, getDownloadURL, uploadBytes, uploadBytesResumable } from "firebase/storage";
import { db, storage } from '@/firebase';


const issues = ref();

const eventRef = collection(db, "issues");

export function useIssues() {

    const fetchissues = async () => {
        try {

            const querySnapshot = await getDocs(eventRef);

            issues.value = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data()
            }));
        } catch (error) {
            console.error("Error fetching issues:", error);
        }
    };


    const create = async (event: any) => {
        try {
            const docRef = await addDoc(eventRef, event);
            console.log("Document written with ID: ", docRef.id);


        } catch (error) {
            console.error("Error creating issues:", error);
        }
    };

    const update = async (id: any, eventt: any) => {
        try {
            const docRef = await updateDoc(id, eventt);

            console.log("Document updated with ID: ", docRef);

        } catch (error) {
            console.error("Error updating issues:", error);
        }
    };

    const remove = async (eventId: any) => {
        try {
            const docRef = await deleteDoc(eventId);

            console.log("Document deleted with ID: ", docRef);

        } catch (error) {
            console.error("Error deleting issues:", error);
        }
    };


    const findOne = async (eventId: any) => {
        const docRef = doc(db, "issues", eventId);
        try {
            const evRef = await getDoc(docRef);

            console.log("Document find one with ID: ", evRef.id);
            return evRef.data();
        } catch (error) {
            console.error("Error finding issues:", error);
        }
    };


    //storage
    const uploadIssuesImage = (issueObj: any) => {

        const imageRef = fileRef(storage, `issues/${issueObj.image.name}`);
        const metadata = {
            contentType: 'image/jpeg',
        };
        const uploadTask = uploadBytesResumable(imageRef, issueObj.image, metadata)

        uploadTask.on('state_changed',
            (snapshot) => {
                // Observe state change issues such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                    case 'paused':
                        console.log('Upload is paused');
                        break;
                    case 'running':
                        console.log('Upload is running');
                        break;
                }
            },
            (error: any) => {
                // Handle unsuccessful uploads
            },
            () => {
                
                getDownloadURL(uploadTask.snapshot.ref).then( async (downloadURL) => {
                    console.log('File available at', downloadURL);
                    //create the event
                    issueObj.image = downloadURL
                    //issueObj['status'] = "Draft"
                   await  create(issueObj);

                });
            });
        }


        const uploadIssuesImageUpdate = (id: any, issueObj: any) => {

            if (!issueObj.image || !issueObj.image.type.startsWith('image/')) {
                throw new Error('Invalid image file. Please select a JPEG or PNG image.');
              }

            const imageName = `${Date.now()}-${issueObj.image.name}`;
            const imageRef = fileRef(storage, `issues/${imageName}`);

            const metadata = {
                contentType: issueObj.image.type,
            };
            const uploadTask = uploadBytesResumable(imageRef, issueObj.image, metadata)
    
            uploadTask.on('state_changed',
                (snapshot) => {
                    // Observe state change issues such as progress, pause, and resume
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log('Upload is ' + progress + '% done');
                    switch (snapshot.state) {
                        case 'paused':
                            console.log('Upload is paused');
                            break;
                        case 'running':
                            console.log('Upload is running');
                            break;
                    }
                },
                (error: any) => {
                    // Handle unsuccessful uploads
                },
               async  () => {
                    
                  const downloadURL =  await getDownloadURL(uploadTask.snapshot.ref);

                  issueObj.image = downloadURL
                  console.log("ID", id);
                  
                    await  update(id, issueObj);
                });
            }

        onMounted(fetchissues);

        return {
            issues,
            create,
            update,
            remove,
            findOne,
            uploadIssuesImage,
            uploadIssuesImageUpdate
        };
    }
