import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Dashboard from "../views/AppDashboard.vue";
import Issues from "../views/AppIssues.vue";
import Login from "../views/AppLogin.vue";
import EventNew from "@/components/events/AppAdd.vue";
import EventEdit from "@/components/events/AppEdit.vue";

import IssueNew from "@/components/issues/AppAdd.vue";
import IssueEdit from "@/components/issues/AppEdit.vue";

import Events from "../views/AppEvents.vue";
import EventDetails from "../views/AppEventDetails.vue";
import Adverts from "../views/AppAdverts.vue";
import Chart from "../views/ChartView.vue";
import Blank from "../views/BlankView.vue";
import NotFound from "../views/NotFound.vue";
import AppIssueDetails from "@/views/AppIssueDetails.vue";


import AdvertNew from "@/components/adverts/AppAdd.vue";
import AdvertEdit from "@/components/adverts/AppEdit.vue";
import AdvertsDetails from "@/views/AdvertsDetails.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Login",
    component: Login,
    meta: { layout: "empty" },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/issues",
    name: "Issues",
    component: Issues,
  },
  {
    path: "/issues/:id/edit",
    name: "IssuesEdit",
    component: IssueEdit,
  },
  {
    path: "/issues/:id",
    name: "IssueDetails",
    component: AppIssueDetails,
  },
  
  {
    path: "/issues/new",
    name: "IssueNew",
    component: IssueNew,
  },
  {
    path: "/events",
    name: "Events",
    component: Events,
  },
  {
    path: "/adverts",
    name: "Adverts",
    component: Adverts,
  },
  {
    path: "/adverts/new",
    name: "AdvertsNew",
    component: AdvertNew,
  },
  {
    path: "/adverts/:id/edit",
    name: "AdvertsEdit",
    component: AdvertEdit,
  },

  {
    path: "/adverts/:id",
    name: "AdvertsDetails",
    component: AdvertsDetails,
  },
  
  {
    path: "/events/:id",
    name: "EventsDetails",
    component: EventDetails,
  },
  {
    path: "/events/:id/edit",
    name: "EventsEdit",
    component: EventEdit,
  },
  {
    path: "/events/new",
    name: "EventsNew",
    component: EventNew,
  },

  // AdvertsNew
  // {
  //   path: "/charts",
  //   name: "Chart",
  //   component: Chart,
  // },
  // {
  //   path: "/blank",
  //   name: "Blank",
  //   component: Blank,
  // },
  { path: "/:pathMatch(.*)*", component: NotFound },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
